import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  hero,
  image,
  highlightsSection,
  highlightsHero,
  partners,
  heroQuote,
  productsWithLinks,
  footer,
} from "../../../data/appetize/education-campuses";
import { enterpriseSupport } from "../../../data/spoton-contacts";

const SimpleHero = loadable(() =>
  import("../../../components-v2/Sections/Hero/SimpleHero")
);
const LargeImage = loadable(() =>
  import("../../../components-v2/Sections/LargeImage")
);
const VerticalPageHighlights = loadable(() =>
  import("../../../components-v2/Sections/VerticalPageHighlights")
);
const HighlightsHero = loadable(() =>
  import("../../../components-v2/Sections/Hero/HighlightsHero")
);
const PartnerLogosAppetize = loadable(() =>
  import("../../../components-v2/Sections/PartnerLogos/partner-logos-appetize")
);
const SecondaryHeroTitleWithQuotes = loadable(() =>
  import("../../../components-v2/Sections/Hero/SecondaryHeroTitleWithQuote")
);
const ProductsWithLinksPlainBackground = loadable(() =>
  import("../../../components-v2/Sections/ProductsWithLinksPlainBackground")
);
const FooterHero = loadable(() =>
  import("../../../components-v2/Sections/Hero/FooterHero")
);

const EducationCampuses = ({}) => {
  return (
    <Layout className="non-fixed-width a-ec" {...enterpriseSupport}>
      <SEO
        loadChat={false}
        title="Education POS Software | College, University, & School POS Solutions | SpotOn"
        description="Our all-in-one Education POS system is made for students, staff and vistors & give your business insights. We offer one complete point of sale solution. Get a demo! "
        cannonical="https://www.spoton.com/enterprise/education-campuses/"
      />
      <SimpleHero className="mt-32 lg:mt-40" {...hero} />

      <LargeImage className="mt-16 lg:mt-24" {...image} />

      <VerticalPageHighlights
        className="mt-20 lg:mt-72"
        {...highlightsSection}
      />

      <HighlightsHero className="mt-20 lg:mt-36" {...highlightsHero} />

      <PartnerLogosAppetize className="mt-20 lg:mt-36" {...partners} />

      <SecondaryHeroTitleWithQuotes className="mt-20 lg:mt-36" {...heroQuote} />

      <ProductsWithLinksPlainBackground
        className="mt-20 lg:mt-36"
        {...productsWithLinks}
      />

      <FooterHero className="my-20 lg:my-36" {...footer} />
    </Layout>
  );
};

export default EducationCampuses;

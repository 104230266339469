export const hero = {
  title: "Education POS system for campuses",
  subTitle: "Education Campuses",
  subtext:
    "Upgrade to an intelligent POS system so you can shorten checkout lines and consolidate data from your campus stadium and dining halls.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/education-campuses/demo",
  },
};

export const image = {
  imageName: "campuses-hero.png",
  mobileImageName: "campuses-hero-mobile.png",
  title: "EDUCATION CAMPUSES",
};

export const highlightsSection = {
  title: "Keep fans in their seats at collegiate events",
  imageName: "campuses-highlights.png",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/education-campuses/demo",
  },
  highlights: [
    {
      title: "Order from \n anywhere",
      subtext:
        "Allow students and fans to place orders from anywhere with smart self-serve kiosks and mobile and in-app tools to expedite lines and direct-to-seat delivery.",
    },
    {
      title: "Shorten \n lines",
      subtext:
        "Manage peak rush periods more smoothly with easy-to-use POS, self-serve, and mobile and online ordering tools to accelerate transactions—so students can grab and go.",
    },
    {
      title: "Accept all \n payment types",
      subtext:
        "Go beyond accepting popular payment types and ensure that students are able to use their meal plan cards and fans can use stored value for every purchase.",
    },
  ],
};

export const highlightsHero = {
  image: {
    imageName: "campuses-highlights-2.png",
    mobileImageName: "campuses-highlights-2-mobile.png",
    title: "World-class technology and service",
    subtext: "ENTERPRISE SOLUTIONS",
  },
  title: "Create efficiency during the lunch rush",
  highlights: [
    {
      title: "Multiple ways \n to order",
      subtext:
        "Manage peak rush periods more efficiently with easy-to-use POS, self-serve kiosks, and order-ahead technologies.",
    },
    {
      title: `More “grab and go” \n options`,
      subtext:
        "Take advantage of POS and kiosk scale integrations so students and staff can breeze through the checkout lines.",
    },
    {
      title: "Complete \n visibility",
      subtext:
        "Track most popular items, busiest location, inventory, and more so you can plan accordingly in the future.",
    },
  ],
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/education-campuses/demo",
  },
};

export const partners = {
  title: "Partnered with the leaders in your industry",
  desktopImageName: "appetize-campuses-partners-desktop.png",
  mobileImageName: "appetize-campuses-partners-mobile.png",
};

export const heroQuote = {
  title: "Powering transactions at LSU",
  quote: `"We have been working with SpotOn since 2017 and recently deployed kiosks to enhance our food service and offer a more convenient and frictionless experience for our students and guests. SpotOn made it extremely easy for us to deploy a self-service platform and shift toward the future of ordering at athletic events."`,
  personName: "Matthew LaBorde",
  personPosition: "Assistant Athletic Director Louisiana State University",
  imageName: "campuses-hero-quote.png",
  mobileImageName: "campuses-hero-quote-mobile.png",
};

export const productsWithLinks = {
  title:
    "Built on a true omnichannel platform to support the evolution of campus life",
  subtext:
    "Offer a dining, concessions, and retail experience that accommodates your students, sports fans, and staff. Upgrade your entire suite of on-campus point-of-sale systems to a powerful, centralized platform to deliver ordering methods for users of all generations, while making smarter, more strategic decisions for your organization.",
  products: [
    {
      title: "Kiosks",
      description:
        "Give your guests direct control over their ordering experience as you speed up order fulfillment and improve your bottom line.",
      linkTo: "/enterprise/kiosks",
      imageName: "products-kiosk-2.png",
    },
    {
      title: "Handhelds",
      description:
        "Take orders and process payments courtside, tableside, or while guests are still in line using on-the-go point of sale solutions.",
      linkTo: "/enterprise/handhelds",
      imageName: "products-handheld.png",
    },
    {
      title: "Kitchen Displays",
      description:
        "Instantly transmit all POS orders to a centralized device for preparation, even if your internet connection goes down.",
      linkTo: "/enterprise/kitchen-displays",
      imageName: "products-kitchen-display.png",
    },
  ],
};

export const footer = {
  title: "Book a consultation",
  subtext:
    "See for yourself how our enterprise cloud POS and management platform can transform your guest experience, provide valuable business insights, and deliver ROI to your campus.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/education-campuses/demo",
  },
};
